import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "username",
    "password",
    "code",
    "passwordFields",
    "codeFields",
    "toggleButton",
    "toggleText",
    "spinner",
    "lockIcon",
    "form",
  ];

  connect() {
    if (!this.element.querySelector("form[data-login-target='form']")) {
      console.warn(
        "Login controller loaded outside login page. Disconnecting.",
      );
      this.disconnect();
    } else {
      console.log("Login controller connected.");
      this.showPasswordFields();
    }
  }

  toggleLoginType(event) {
    event.preventDefault();

    const isPasswordMode =
      !this.passwordFieldsTarget.classList.contains("hidden");

    if (isPasswordMode) {
      this.showCodeFields();
      this.toggleTextTarget.textContent = "Login with password";
      this.formTarget.action = "/login/code"; // Update form action for code login
      this.requestCode();
    } else {
      this.showPasswordFields();
      this.toggleTextTarget.textContent = "Login with in-world code";
      this.formTarget.action = "/session"; // Reset form action to default
    }
  }

  showPasswordFields() {
    console.log("Showing password fields");
    this.passwordFieldsTarget.classList.remove("hidden");
    this.codeFieldsTarget.classList.add("hidden");
    this.passwordTarget.required = true;
    this.codeTarget.required = false;
  }

  showCodeFields() {
    console.log("Showing code fields");
    this.passwordFieldsTarget.classList.add("hidden");
    this.codeFieldsTarget.classList.remove("hidden");
    this.passwordTarget.required = false;
    this.codeTarget.required = true;
  }

  startLoading() {
    this.toggleButtonTarget.disabled = true;
    this.spinnerTarget.classList.remove("hidden");
    this.lockIconTarget.classList.add("hidden");
  }

  stopLoading() {
    this.toggleButtonTarget.disabled = false;
    this.spinnerTarget.classList.add("hidden");
    this.lockIconTarget.classList.remove("hidden");
  }

  requestCode() {
    const username = this.usernameTarget.value;

    if (!username) {
      alert("Please enter your username first");
      this.showPasswordFields();
      return;
    }

    this.startLoading();

    fetch("/login/request_code", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ username: username }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          alert("A login code has been sent to you in Second Life");
        } else {
          alert(data.message);
          this.showPasswordFields();
        }
      })
      .catch(() => {
        alert("An error occurred. Please try again later.");
        this.showPasswordFields();
      })
      .finally(() => {
        this.stopLoading();
      });
  }

  reset() {
    const username = this.usernameTarget.value;

    if (!username) {
      alert("Please enter your username first");
      return;
    }

    this.startLoading();

    fetch("/password/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ username: username }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          alert("Your new password has been sent to you in Second Life");
        } else {
          alert(data.message);
        }
      })
      .catch(() => {
        alert("An error occurred. Please try again later.");
      })
      .finally(() => {
        this.stopLoading();
      });
  }
}
